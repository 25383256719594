<template>
  <v-row v-if="reservationDone" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{
              already_done
                ? $t("inscriptions.already_done")
                : $tc("inscriptions.customer_studio")
            }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center" v-if="$store.getters['auth/isLoggedIn']">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'appointmentList' })"
          >{{ $tc("appointments.go", 2) }}</v-btn
        >
      </v-row>
      <v-row justify="center" v-else>
        <v-btn
          style="height: 30px"
          outlined
          @click="
            $router.push({
              name: 'appointmentViewOutsite',
              params: {
                cliente_id: appointment.customer_id,
                id: appointment.id,
              },
            })
          "
          >{{ $tc("appointments.see", 2) }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <tattooer-card v-else>
    <template slot-scope="{}">
      <payment v-if="pi" :pi="pi" @payed="reservationDone = true" />

      <v-stepper v-model="e1" alt-labels v-else>
        <v-stepper-header>
          <v-spacer />
          <v-stepper-step step="1" :complete="e1 > 1"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("appointments.info_cliente") }}
            </div></v-stepper-step
          >

          <v-spacer />
          <v-stepper-step step="2" :complete="e1 > 2"
            ><div
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{
                $store.getters["inscriptions/getTattooerLaborExange"](
                  customer.studio
                ) === "day"
                  ? $t("appointments.day_hour")
                  : $t("appointments.day")
              }}
            </div></v-stepper-step
          >

          <v-spacer />

          <!--<v-divider></v-divider>

          <v-stepper-step step="2" :complete="e1 > 2"
            >Paga y señal</v-stepper-step
          >-->
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <customer-info
              :appointment="appointment"
              @validated="e1++"
            ></customer-info>
          </v-stepper-content>
          <v-stepper-content :step="2">
            <date-time-select
              ref="dateTimeSelect"
              @validated="save"
              @back="e1--"
              select="tattooer"
              v-if="
                $store.getters['inscriptions/getTattooerLaborExange'](
                  customer.studio
                ) === 'day'
              "
            ></date-time-select>
            <LaborExange
              v-else
              @validated="save"
              @back="e1--"
              ref="labor"
              :type="
                $store.getters['inscriptions/getTattooerLaborExange'](
                  customer.studio
                )
              "
            ></LaborExange>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </tattooer-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ClientIncription",
  components: {
    TattooerCard: () => import("@/components/inscription/tattooer/Card"),
    CustomerInfo: () =>
      import("@/components/inscription/customer/CustomerInfo"),
    Payment: () => import("@/components/inscription/customer/Payment"),
    DateTimeSelect: () =>
      import("@/components/inscription/customer/DateTimeSelect"),
    LaborExange: () => import("@/components/inscription/customer/LaborExange"),
  },
  mounted() {
    this.fetchAppointment();
  },
  data() {
    return {
      reservationDone: false,
      already_done: false,
      e1: 1,
      pi: null,
      selected: false,
      error_check: false,
    };
  },
  computed: {
    ...mapState("inscriptions", ["customerState"]),
    customer: {
      get() {
        return this.customerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_CUSTOMER", value);
      },
    },
    studio() {
      let policy = this.appointment.studio.user.setting.find((x) => {
        return x.value == "link";
      });
      console.log(policy);

      return policy ? policy : "none";
    },
    link() {
      let policy = this.appointment.studio.user.setting.find((x) => {
        return x.key == "link_appointment";
      });
      console.log(policy);
      return policy;
    },
    URL() {
      return `${window.location.origin}/?#/inscription/policy/studio/${this.$route.params.token}`;
    },
  },
  methods: {
    ...mapActions("inscriptions", ["getAppointmentById"]),
    fetchAppointment() {
      this.getAppointmentById(this.$route.params.id).then((response) => {
        //console.log(response.status);
        this.appointment = response;
        if (response.status !== "pre_inscription") {
          //this.reservationDone = true;
          //this.already_done = true;
          //return;
        }
        let customer = {
          ...this.appointment.customer,
          description: this.appointment.description,
          studio: this.appointment.studio,
          tattooer: this.appointment.tattooer || { id: -1 },
          files: [],
        };
        this.$store.commit("inscriptions/SET_CUSTOMER", customer);
        this.$store.commit(
          "inscriptions/SET_TATTOOER",
          this.appointment.tattooer
        );
      });
    },
    ...mapActions("inscriptions", ["customerPostInscription"]),
    save() {
      this.customerPostInscription(this.$route.params.id).then((data) => {
        //console.log(data);
        if (data.marketplace) this.pi = data.marketplace;
        else this.reservationDone = true;
      });
    },
    async validateForm(scope) {
      this.errors.clear();

      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.save();
        }
      });
    },
  },
};
</script>
