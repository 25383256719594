var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservationDone)?_c('v-row',{staticClass:"final",staticStyle:{"overflow":"hidden"}},[_c('v-img',{attrs:{"height":"900px","src":require("@/assets/background.jpg")}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top:8%'
          : _vm.$vuetify.breakpoint.lgAndDown
          ? 'margin-top: 5%'
          : ''),attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/logo.png")}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"4","src":"@/assets/background.jpg"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","color":"white !important","font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.already_done ? _vm.$t("inscriptions.already_done") : _vm.$tc("inscriptions.customer_studio"))),_c('br')])])],1),(_vm.$store.getters['auth/isLoggedIn'])?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'appointmentList' })}}},[_vm._v(_vm._s(_vm.$tc("appointments.go", 2)))])],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
            name: 'appointmentViewOutsite',
            params: {
              cliente_id: _vm.appointment.customer_id,
              id: _vm.appointment.id,
            },
          })}}},[_vm._v(_vm._s(_vm.$tc("appointments.see", 2)))])],1)],1)],1):_c('tattooer-card',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [(_vm.pi)?_c('payment',{attrs:{"pi":_vm.pi},on:{"payed":function($event){_vm.reservationDone = true}}}):_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-spacer'),_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.e1 > 1}},[_c('div',{staticStyle:{"text-align":"center","color":"var(--v-primary-base)","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("appointments.info_cliente"))+" ")])]),_c('v-spacer'),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.e1 > 2}},[_c('div',{staticStyle:{"text-align":"center","color":"var(--v-primary-base)","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$store.getters["inscriptions/getTattooerLaborExange"]( _vm.customer.studio ) === "day" ? _vm.$t("appointments.day_hour") : _vm.$t("appointments.day"))+" ")])]),_c('v-spacer')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('customer-info',{attrs:{"appointment":_vm.appointment},on:{"validated":function($event){_vm.e1++}}})],1),_c('v-stepper-content',{attrs:{"step":2}},[(
              _vm.$store.getters['inscriptions/getTattooerLaborExange'](
                _vm.customer.studio
              ) === 'day'
            )?_c('date-time-select',{ref:"dateTimeSelect",attrs:{"select":"tattooer"},on:{"validated":_vm.save,"back":function($event){_vm.e1--}}}):_c('LaborExange',{ref:"labor",attrs:{"type":_vm.$store.getters['inscriptions/getTattooerLaborExange'](
                _vm.customer.studio
              )},on:{"validated":_vm.save,"back":function($event){_vm.e1--}}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }